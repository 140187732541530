import React, { Component, createRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { API_ENDPOINT } from '../constants';
import Isvg from 'react-inlinesvg';

import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';

import hamburgerLight from '../assets/svg/hamburger-light.svg';
import hamburgerDark from '../assets/svg/hamburger-dark.svg';
import closeIcon from '../assets/svg/close.svg';
import searchLight from '../assets/svg/search-light.svg';
import searchDark from '../assets/svg/search-dark.svg';
import logoLight from '../assets/svg/logo-light.svg';
import logoDark from '../assets/svg/logo-dark.svg';
import cartIcon from '../assets/svg/cart.svg';
import wishlistIcon from '../assets/svg/whishlist-icon.svg';

import test from '../assets/images/sm1.jpg';
import { timing } from 'react-ga';
import noImage from '../assets/images/no-image.png';
import { parseJSON } from 'date-fns';
import Menu from './menu';

const langsDict = {
  en: 'English',
  sv: 'Swedish',
};

const parseJSON1 = (response) => {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    };
  });
};

export class Nav extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      desktopMenu: false,
      langDrop: false,
      subCat: false,
      searchBox: false,
      searchBoxDark: false,
      searchResult: [],
    };
    this.textInput = createRef();
  }

  handleMenu = (value) => {
    this.setState({
      menu: value,
    });
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentDidUpdate(prevProps) {
    this.textInput.current.focus();

    if (
      prevProps[0].location.pathname != this.props[0].location.pathname &&
      this.state.desktopMenu
    ) {
      this.setState({
        desktopMenu: false,
      });
    }

    if (
      prevProps.menuCategory != this.props.menuCategory &&
      this.props.menuCategory
    ) {
      window.scrollTo(0, 0);
      this.setState(
        {
          menu: this.props.menuCategory,
        },
        () => {
          this.props.handleMenuCategory(null);
        },
      );
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        langDrop: false,
      });
    }

    if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
      this.setState({
        desktopMenu: false,
      });
    }

    if (this.wrapperRef3 && !this.wrapperRef3.contains(event.target)) {
      this.setState({
        desktopMenu: false,
      });
    }

    if (this.wrapperRef4 && !this.wrapperRef4.contains(event.target)) {
      this.setState({
        subCat: false,
      });
    }
  }

  searchFunction = () => {
    let lang = this.props.lang;
    let search = this.state.search;

    fetch(API_ENDPOINT + '/products/search', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify({ lang, search }),
    })
      .then(parseJSON1)
      .then(({ result, status }) => {
        this.setState({
          searchResult: result,
        });
      })
      .catch(() => null);
  };

  render() {
    return (
      <div>
        <nav
          className={
            this.props[0].location.pathname == '/' ||
              this.props[0].location.pathname == '/kontakt'
              ? 'nav'
              : 'nav-static'
          }
        >
          <div
            className={!this.state.subCat ? 'nav-upper' : 'nav-upper bg-yellow'}
          >
            <Container fluid>
              <div className="nav-upper-left">
                <div
                  class="dropdown"
                  ref={(node) => {
                    this.wrapperRef = node;
                  }}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.setState({
                      langDrop: !this.state.langDrop,
                    });
                  }}
                >
                  <a class="dropbtn">{langsDict[this.props.lang]}</a>
                  <div
                    class={
                      this.state.langDrop
                        ? 'dropdown-content open'
                        : 'dropdown-content'
                    }
                  >
                    <a
                      onClick={() => {
                        this.setState({ langDrop: false }, () => {
                          this.props.setLang('en');
                        });
                      }}
                    >
                      English
                    </a>

                    <a
                      onClick={() =>
                        this.setState({ langDrop: false }, () => {
                          this.props.setLang('sv');
                        })
                      }
                    >
                      Swedish
                    </a>
                  </div>
                </div>
                |
                <Link to="/fsc">
                  {'FSC® - CERTIFIKAT'.translate(this.props.lang)}
                </Link>
              </div>
              <div className="nav-upper-right">
                <Link to="/hitta-aterforsaljare">
                  {'HITTA BUTIK'.translate(this.props.lang)}
                </Link>

                <Link to="/faq">{'FAQ'.translate(this.props.lang)}</Link>
                <Link to="/kontakt">
                  {'KONTAKT'.translate(this.props.lang)}
                </Link>
                {!this.props.uData ? (
                  <Link to="/bli-aterforsaljare">
                    {'BLI ÅTERFÖRSÄLJARE'.translate(this.props.lang)}
                  </Link>
                ) : null}
{/*                {!this.props.uData ? (
                    <Link to="/bli-foretagskund">
                      {'BLI FÖRETAGSKUND'.translate(this.props.lang)}
                    </Link>
                ) : null}*/}
                <Link to="/account">
                  {this.props.uData
                    ? 'MINA SIDOR'.translate(this.props.lang)
                    : 'Logga in'.translate(this.props.lang)}
                </Link>
              </div>
            </Container>
          </div>
          {this.props[0].location.pathname == '/' ? (
            <div className="nav-body nav-body-light">
              <Container fluid>
                <div className="menu-icon">
                  <Isvg
                    src={hamburgerLight}
                    onClick={() => {
                      this.setState({
                        menu: true,
                      });
                    }}
                  />
                </div>
                <div
                  className={
                    this.state.desktopMenu
                      ? 'desktop-menu opened'
                      : 'desktop-menu'
                  }
                >
                  <Isvg
                    src={closeIcon}
                    className="close-icon"
                    onClick={() => {
                      this.setState({
                        menu: false,
                      });
                    }}
                  />
                  <ul
                    ref={(node) => {
                      this.wrapperRef2 = node;
                    }}
                  >
                    {this.props.rootCategories.map((item, idx) => {
                      if (
                        Object.translate(item, 'breadcrumb', this.props.lang) !=
                        '/josefin-lustig'
                      )
                        return (
                          <li key={idx}>
                            <Link
                              to={Object.translate(
                                item,
                                'breadcrumb',
                                this.props.lang,
                              )}
                            >
                              {Object.translate(item, 'name', this.props.lang)}
                            </Link>
                          </li>
                        );
                    })}
                    <li>
                      <Link to="/vara-skotselrad">
                        {'VÅRA SKÖTSELRÅD'.translate(this.props.lang)}
                      </Link>
                    </li>
                    <li>
                      <Link to="/jobba-hos-oss">
                        {'JOBBA HOS OSS'.translate(this.props.lang)}
                      </Link>
                    </li>
                    {!this.props.uData ? (
                      <li>
                        <Link to="/bli-aterforsaljare">
                          {'BLI ÅTERFÖRSÄLJARE'.translate(this.props.lang)}
                        </Link>
                      </li>
                    ) : null}
                    <li>
                      <Link to="/account">
                        {'Logga in'.translate(this.props.lang)}
                      </Link>
                    </li>
                    <li>
                      <Link to="/kontakt">
                        {'KONTAKT'.translate(this.props.lang)}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="logo">
                  <Link to="/">
                    <Isvg src={logoLight} />
                  </Link>
                </div>
                <div className="search" style={{ position: 'relative' }}>
                  {this.props.uData || this.props.cartInfo || (typeof window !== 'undefined' &&
                    String(localStorage.getItem('guestToken')).indexOf(
                      'retrack-',
                    ) !== -1) ? (
                    <div className="cart">
                      <Link className="cart" to="/cart">
                        <Isvg src={cartIcon} className="light-icon" />{' '}
                        <div className="counter second-color">
                          {this.props.cartInfo && this.props.cartInfo.items
                            ? this.props.cartInfo.items.reduce((acc, item) => {
                              return acc + item.quantity;
                            }, 0)
                            : 0}
                        </div>{' '}
                        {/*this.props.cartInfo ? `${parseFloat(this.props.cartInfo.total).formatPrice(2)} ${this.props.cartInfo.currency}` : null*/}
                      </Link>
                      <div className="cart-box-holder"></div>
                      {this.props.cartInfo ? (
                        <div
                          className="cart-box-container"
                          style={
                            this.props.displayMiniCart
                              ? { display: 'block' }
                              : {}
                          }
                        >
                          <div className="cart-box">
                            {this.props.cartInfo.items.map((item, idx) => {
                              return (
                                <div className="product">
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      this.props.addToCart(
                                        item._id,
                                        0,
                                        true,
                                        this.props.fetchCartInfo,
                                        false,
                                        item,
                                      );
                                    }}
                                    className="close"
                                  >
                                    x
                                  </span>
                                  <img
                                    src={
                                      item.thumbnailExposed
                                        ? API_ENDPOINT +
                                        item.thumbnailExposed.thumbnail
                                        : item.thumbnailMood
                                          ? API_ENDPOINT +
                                          item.thumbnailMood.thumbnail
                                          : noImage
                                    }
                                  />
                                  <div className="content">
                                    <h6>
                                      {Object.translate(
                                        item,
                                        'name',
                                        this.props.lang,
                                      )}
                                    </h6>
                                    <p>
                                      {item.quantity}x
                                      <span>
                                        {parseFloat(
                                          this.props.uData &&
                                            !this.props.uData.creditCardPayment
                                            ? item.price.value
                                            : item.price.value *
                                            (1 + item.price.vat / 100),
                                        ).formatPrice(2)}{' '}
                                        {item.price.currency}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                            <div className="price">
                              <p>
                                {'Delsumma:'.translate(this.props.lang)}{' '}
                                <span>
                                  {parseFloat(
                                    this.props.cartInfo.total,
                                  ).formatPrice(2)}{' '}
                                  {this.props.cartInfo.currency}
                                </span>
                              </p>
                            </div>
                            <Link to="/cart">
                              <button>
                                {'Visa Varukorg'.translate(this.props.lang)}
                              </button>
                            </Link>
                            {this.props.uData &&
                              !this.props.uData.creditCardPayment &&
                              !this.props.uData.isVirtual  ? (
                              <Link to="/checkout">
                                <button>
                                  {'Till kassan'.translate(this.props.lang)}
                                </button>
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {this.props.uData || this.props.wishlist.length ? (
                    <div className="cart">
                      <Link className="cart" to="/account/wishlist">
                        <Isvg src={wishlistIcon} className="dark-icon" />{' '}
                        <div className="counter">
                          {this.props.wishlist.length}
                        </div>{' '}
                      </Link>
                      <div className="cart-box-holder"></div>
                      {this.props.wishlist.length ? (
                        <div className="cart-box-container">
                          <div className="cart-box">
                            {this.props.wishlist.map((item, idx) => {
                              return (
                                <div className="product">
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      this.props.addToWishlist(item._id, true);
                                    }}
                                    className="close"
                                  >
                                    x
                                  </span>
                                  <Link
                                    to={`/products/${Object.translate(
                                      item,
                                      'alias',
                                      this.props.lang,
                                    )}`}
                                  >
                                    <img
                                      src={
                                        item.thumbnailExposed
                                          ? API_ENDPOINT +
                                          item.thumbnailExposed.thumbnail
                                          : item.thumbnailMood
                                            ? API_ENDPOINT +
                                            item.thumbnailMood.thumbnail
                                            : noImage
                                      }
                                    />
                                  </Link>
                                  <Link
                                    to={`/products/${Object.translate(
                                      item,
                                      'alias',
                                      this.props.lang,
                                    )}`}
                                  >
                                    <div className="content">
                                      <h6>
                                        {item.productFamily}{' '}
                                        {Object.translate(
                                          item,
                                          'name',
                                          this.props.lang,
                                        )}
                                      </h6>
                                      {item.price ? (
                                        <p>
                                          <span>
                                            {parseFloat(
                                              this.props.uData &&
                                                !this.props.uData
                                                  .creditCardPayment
                                                ? item.price.value
                                                : item.price.value *
                                                (1 + item.price.vat / 100),
                                            ).formatPrice(2)}{' '}
                                            {item.price.currency}
                                          </span>
                                        </p>
                                      ) : null}
                                    </div>
                                  </Link>
                                </div>
                              );
                            })}
                            {this.props.uData ? (
                              <Link to="/account/wishlist">
                                <button>
                                  {'MINA FAVORITER'.translate(this.props.lang)}
                                </button>
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  <Isvg
                    src={searchLight}
                    className="light-icon"
                    onClick={() => {
                      this.setState({
                        searchBox: !this.state.searchBox,
                      });
                    }}
                  />

                  {/* SEARCH BOX RESULTS UNDER SEARCH ROW */}

                  <div
                    className={
                      this.state.searchBox
                        ? `search-field opened ${this.props.uData ? 'have-cart' : ''
                        }`
                        : 'search-field'
                    }
                  >
                    <input
                      type="text"
                      ref={this.textInput}
                      className={
                        this.state.searchBox ? 'search-box' : 'search-box'
                      }
                      placeholder="Sök efter produkter…"
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState(
                          { search: e.target.value },
                          this.searchFunction,
                        );
                      }}
                      onKeyUp={(e) => {
                        if (e.key == 'Enter') {
                          this.setState({ search: '' });
                          this.props[0].history.push(
                            `/search/${this.state.search}`,
                          );
                        }
                      }}
                    />
                    <div className="search-articles">
                      <Container>
                        {this.state.search === ''
                          ? null
                          : this.state.searchResult &&
                          this.state.searchResult.map((data) => {
                            return (
                              <Link
                                to={`/products/${Object.translate(
                                  data,
                                  'alias',
                                  this.props.lang,
                                )}`}
                              >
                                <Row className="search-article-row">
                                  <Col
                                    md="2"
                                    className="search-article-image"
                                  >
                                    <img
                                      src={
                                        API_ENDPOINT.replace(
                                          'testapi',
                                          'api',
                                        ) + data.images[0].file
                                      }
                                    />
                                  </Col>
                                  <Col className="search-article-name">
                                    {data.productFamily}{' '}
                                    {Object.translate(
                                      data,
                                      'name',
                                      this.props.lang,
                                    )}{' '}
                                    ({data.sku})
                                  </Col>
                                </Row>
                              </Link>
                            );
                          })}
                      </Container>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          ) : (
            <div className="nav-body nav-body-dark">
              <Container fluid>
                <div className="menu-icon">
                  <Isvg
                    src={hamburgerDark}
                    onClick={() => {
                      this.setState({
                        menu: true,
                      });
                    }}
                  />
                </div>
                <div
                  className={
                    this.state.desktopMenu
                      ? 'desktop-menu opened'
                      : 'desktop-menu'
                  }
                >
                  <Isvg
                    src={closeIcon}
                    className="close-icon"
                    onClick={() => {
                      this.setState({
                        menu: false,
                      });
                    }}
                  />
                  <ul
                    ref={(node) => {
                      this.wrapperRef3 = node;
                    }}
                  >
                    {this.props.rootCategories.map((item, idx) => {
                      if (
                        Object.translate(item, 'breadcrumb', this.props.lang) !=
                        '/josefin-lustig'
                      )
                        return (
                          <li key={idx}>
                            <Link
                              to={Object.translate(
                                item,
                                'breadcrumb',
                                this.props.lang,
                              )}
                            >
                              {Object.translate(item, 'name', this.props.lang)}
                            </Link>
                          </li>
                        );
                    })}
                    <li>
                      <Link to="/vara-skotselrad">
                        {'VÅRA SKÖTSELRÅD'.translate(this.props.lang)}
                      </Link>
                    </li>
                    <li>
                      <Link to="/jobba-hos-oss">
                        {'JOBBA HOS OSS'.translate(this.props.lang)}
                      </Link>
                    </li>
                    {!this.props.uData ? (
                      <li>
                        <Link to="/bli-aterforsaljare">
                          {'BLI ÅTERFÖRSÄLJARE'.translate(this.props.lang)}
                        </Link>
                      </li>
                    ) : null}
                    <li>
                      <Link to="/account">
                        {'Logga in'.translate(this.props.lang)}
                      </Link>
                    </li>
                    <li>
                      <Link to="/kontakt">
                        {'KONTAKT'.translate(this.props.lang)}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="logo">
                  <Link to="/">
                    <Isvg src={logoDark} />
                  </Link>
                </div>
                <div className="search" style={{ position: 'relative' }}>
                  {this.props.uData || this.props.cartInfo || (typeof window !== 'undefined' &&
                    String(localStorage.getItem('guestToken')).indexOf(
                      'retrack-',
                    ) !== -1) ? (
                    <div className="cart">
                      <Link className="cart" to="/cart">
                        <Isvg src={cartIcon} className="dark-icon" />{' '}
                        <div className="counter second-color">
                          {this.props.cartInfo && this.props.cartInfo.items
                            ? this.props.cartInfo.items.reduce((acc, item) => {
                              return acc + item.quantity;
                            }, 0)
                            : 0}
                        </div>{' '}
                        {/*this.props.cartInfo ? `${parseFloat(this.props.cartInfo.total).formatPrice(2)} ${this.props.cartInfo.currency}` : null*/}
                      </Link>
                      <div className="cart-box-holder"></div>
                      {this.props.cartInfo &&
                        this.props[0].location.pathname != '/cart' ? (
                        <div
                          className="cart-box-container"
                          style={
                            this.props.displayMiniCart
                              ? { display: 'block' }
                              : {}
                          }
                        >
                          <div className="cart-box">
                            {this.props.cartInfo.items.map((item, idx) => {
                              return (
                                <div className="product">
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      this.props.addToCart(
                                        item._id,
                                        0,
                                        true,
                                        this.props.fetchCartInfo,
                                        false,
                                        item,
                                      );
                                    }}
                                    className="close"
                                  >
                                    x
                                  </span>
                                  <img
                                    src={
                                      item.thumbnailExposed
                                        ? API_ENDPOINT +
                                        item.thumbnailExposed.thumbnail
                                        : item.thumbnailMood
                                          ? API_ENDPOINT +
                                          item.thumbnailMood.thumbnail
                                          : noImage
                                    }
                                  />
                                  <div className="content">
                                    <h6>
                                      {item.productFamily}{' '}
                                      {Object.translate(
                                        item,
                                        'name',
                                        this.props.lang,
                                      )}
                                    </h6>
                                    <p>
                                      {item.quantity}x
                                      <span>
                                        {parseFloat(
                                          this.props.uData &&
                                            !this.props.uData.creditCardPayment
                                            ? item.price.value
                                            : item.price.value *
                                            (1 + item.price.vat / 100),
                                        ).formatPrice(2)}{' '}
                                        {item.price.currency}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                            <div className="price">
                              <p>
                                {'Delsumma:'.translate(this.props.lang)}{' '}
                                <span>
                                  {parseFloat(
                                    this.props.cartInfo.total,
                                  ).formatPrice(2)}{' '}
                                  {this.props.cartInfo.currency}
                                </span>
                              </p>
                            </div>
                            <Link to="/cart">
                              <button>
                                {'Visa Varukorg'.translate(this.props.lang)}
                              </button>
                            </Link>
                            {this.props.uData &&
                              !this.props.uData.creditCardPayment &&
                              !this.props.uData.isVirtual ? (
                              <Link to="/checkout">
                                <button>
                                  {'Till kassan'.translate(this.props.lang)}
                                </button>
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  {this.props.uData || this.props.wishlist.length ? (
                    <div className="cart">
                      <Link className="cart" to="/account/wishlist">
                        <Isvg src={wishlistIcon} className="dark-icon" />{' '}
                        <div className="counter">
                          {this.props.wishlist.length}
                        </div>{' '}
                      </Link>
                      <div className="cart-box-holder"></div>
                      {this.props.wishlist.length ? (
                        <div className="cart-box-container">
                          <div className="cart-box">
                            {this.props.wishlist.map((item, idx) => {
                              return (
                                <div className="product">
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      this.props.addToWishlist(item._id, true);
                                    }}
                                    className="close"
                                  >
                                    x
                                  </span>
                                  <Link
                                    to={`/products/${Object.translate(
                                      item,
                                      'alias',
                                      this.props.lang,
                                    )}`}
                                  >
                                    <img
                                      src={
                                        item.thumbnailExposed
                                          ? API_ENDPOINT +
                                          item.thumbnailExposed.thumbnail
                                          : item.thumbnailMood
                                            ? API_ENDPOINT +
                                            item.thumbnailMood.thumbnail
                                            : noImage
                                      }
                                    />
                                  </Link>
                                  <Link
                                    to={`/products/${Object.translate(
                                      item,
                                      'alias',
                                      this.props.lang,
                                    )}`}
                                  >
                                    <div className="content">
                                      <h6>
                                        {item.productFamily}{' '}
                                        {Object.translate(
                                          item,
                                          'name',
                                          this.props.lang,
                                        )}
                                      </h6>
                                      {item.price ? (
                                        <p>
                                          <span>
                                            {parseFloat(
                                              this.props.uData &&
                                                !this.props.uData
                                                  .creditCardPayment
                                                ? item.price.value
                                                : item.price.value *
                                                (1 + item.price.vat / 100),
                                            ).formatPrice(2)}{' '}
                                            {item.price.currency}
                                          </span>
                                        </p>
                                      ) : null}
                                    </div>
                                  </Link>
                                </div>
                              );
                            })}
                            {this.props.uData ? (
                              <Link to="/account/wishlist">
                                <button>
                                  {'MINA FAVORITER'.translate(this.props.lang)}
                                </button>
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  <Isvg
                    src={searchDark}
                    onClick={() => {
                      this.setState({
                        searchBoxDark: !this.state.searchBoxDark,
                      });
                    }}
                  />

                  <div
                    className={
                      this.state.searchBoxDark
                        ? `search-field opened ${this.props.uData ? 'have-cart' : ''
                        }`
                        : 'search-field'
                    }
                  >
                    <input
                      id="search-input"
                      type="text"
                      ref={this.textInput}
                      className={
                        this.state.searchBoxDark ? 'search-box' : 'search-box'
                      }
                      placeholder="Sök efter produkter…"
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState(
                          { search: e.target.value },
                          this.searchFunction,
                        );
                      }}
                      onKeyUp={(e) => {
                        if (e.key == 'Enter') {
                          this.setState({ search: '' });
                          this.props[0].history.push(
                            `/search/${this.state.search}`,
                          );
                        }
                      }}
                    />
                    <div className="search-articles">
                      <Container>
                        {this.state.search === ''
                          ? null
                          : this.state.searchResult &&
                          this.state.searchResult.map((data) => {
                            return (
                              <Link
                                to={`/products/${Object.translate(
                                  data,
                                  'alias',
                                  this.props.lang,
                                )}`}
                              >
                                <Row className="search-article-row">
                                  <Col
                                    md="2"
                                    className="search-article-image"
                                  >
                                    <img
                                      src={
                                        API_ENDPOINT.replace(
                                          'testapi',
                                          'api',
                                        ) + data.images[0].file
                                      }
                                    />
                                  </Col>
                                  <Col className="search-article-name">
                                    {data.productFamily}{' '}
                                    {Object.translate(
                                      data,
                                      'name',
                                      this.props.lang,
                                    )}{' '}
                                    ({data.sku})
                                  </Col>
                                </Row>
                              </Link>
                            );
                          })}
                      </Container>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          )}
          {this.props[0].location.pathname !== '/' ? (
            <div
              className={
                this.state.subCat ? 'nav-list list-dropdown' : 'nav-list'
              }
            >
              <ul
                className="menu"
                ref={(node) => {
                  this.wrapperRef4 = node;
                }}
              >
                {/* {
                                        this.props.rootCategories.map((item, idx) => {
                                            if (Object.translate(item, 'breadcrumb', this.props.lang) != '/josefin-lustig')
                                                return (
                                                    <li key={idx} onMouseEnter={() => {
                                                        this.setState({
                                                            subCat: item._id == this.state.subCat ? null : item._id
                                                        })

                                                    }} onMouseLeave={() => {
                                                        this.setState({
                                                            subCat: null
                                                        })

                                                    }} onClick={() => {
                                                        this.setState({
                                                            subCat: item._id == this.state.subCat ? null : item._id
                                                        })
                                                    }}>
                                                        <Link to={Object.translate(item, 'breadcrumb', this.props.lang)}>{Object.translate(item, 'name', this.props.lang)}</Link>
                                                        {
                                                            item.children && item.children.length ?
                                                                <ul className={this.state.subCat != item._id ? 'dropdown-menu' : 'dropdown-menu opened'}>
                                                                    {
                                                                        item.children.map((children, cidx) => {
                                                                            return (
                                                                                <li key={cidx}>
                                                                                    <Link to={Object.translate(children, 'breadcrumb', this.props.lang)}>{Object.translate(children, 'name', this.props.lang)}</Link>
                                                                                </li>

                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                                :
                                                                null
                                                        }
                                                    </li>

                                                )
                                        })
                                    } */}
                {this.props.rootCategories
                  .filter((item) => !item.brandCategory)
                  .map((item, idx) => {
                    if (
                      Object.translate(item, 'breadcrumb', this.props.lang) !=
                      '/josefin-lustig'
                    )
                      if (idx < 2) {
                        return (
                          <li
                            key={idx}
                            onClick={() => {
                              this.props.handleMenuCategory(item);
                            }}
                          >
                            <Link
                              to={Object.translate(
                                item,
                                'breadcrumb',
                                this.props.lang,
                              )}
                            >
                              {Object.translate(item, 'name', this.props.lang)}
                            </Link>
                          </li>
                        );
                      } else {
                        return (
                          <li>
                            <Link
                              to={Object.translate(
                                item,
                                'breadcrumb',
                                this.props.lang,
                              )}
                            >
                              {Object.translate(item, 'name', this.props.lang)}
                            </Link>
                          </li>
                        );
                      }
                  })}
                <li
                  onClick={() => {
                    this.props.handleMenuCategory(true);
                  }}
                >
                  <Link>{'VARUMÄRKEN'.translate(this.props.lang)}</Link>
                </li>
              </ul>
            </div>
          ) : null}

          {this.state.subCat ? <div className="height-helper"></div> : null}
          {this.state.menu ? (
            <Menu
              {...this.props}
              handleMenu={this.handleMenu}
              parent={this.state.menu}
            ></Menu>
          ) : null}
        </nav>
      </div>
    );
  }
}

export default Nav;
