import React, { Component } from 'react';
import Page from '../containers/page';
import {
    Col,
    Container, Row,

} from 'reactstrap';
import image from '../assets/images/account-cover.png';
import imageMobile from '../assets/images/account-cover.png';
import {getSearchParams} from "../helpers/linkHelper";

class BriqpayConfirmationPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            html: null,
            status: null
        };
    }
    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
            ).then((data) => {
                this.setState({
                    ...data,
                });
            });
        }

        let params = getSearchParams(this.props[0].location.search, {});

        if (this.props.loadDataWithQuery) {
            for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
                this.props.loadDataWithQuery[i](
                    window.fetch,
                    this.props.lang,
                    this.props[0].match,
                    params,
                    this.props[0].location.pathname,
                ).then((data) => {
                    console.log("Momo", data)
                    this.setState(
                        {
                            ...data,
                            loading: null,
                        },
                        () => {
                            this.props.updateMeta(
                                this.props.generateSeoTags(this.state, this.props.lang),
                            );
                        },
                    );
                });
            }
        }

        this.props.updateMeta(
            this.props.generateSeoTags(this.state, this.props.lang),
        );
    };
    async componentDidMount() {
        console.log('LOAD CONFIRMATION!')
        this.get()
    }

    render() {
        return (
            <div className="account-page">
                <section className="test">
                    <img
                        src={
                            typeof window !== 'undefined' && window.innerWidth < 768
                                ? imageMobile
                                : image
                        }
                    />
                    <div className="test-content">
                        <h1>{'BLI FÖRETAGSKUND'.translate(this.props.lang)}</h1>
                    </div>
                </section>

                <Container>
                    <Row style={{ justifyContent: 'center', padding: '30px 0' }}>
                        <Col lg="12">
                            <div
                                className="account-container"
                                style={{ textAlign: 'center' }}
                            >
                                <h3 style={{ fontSize: 24, marginBottom: 10 }}>
                                    {'Tack för er registrering!'.translate(this.props.lang)}
                                </h3>
                                <p style={{ fontSize: 14 }}>
                                    {'Ni får inom kort inloggningsuppgifter skickade till er valda mejl.'.translate(
                                            this.props.lang,
                                        )}
                                </p>
                            </div>
                        </Col>

                    </Row>
                </Container>

            </div>
        );
    }
}

export default Page(BriqpayConfirmationPage);
