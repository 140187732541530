import React, { Component } from 'react';
import {Field, reduxForm, formValueSelector, change} from 'redux-form';
// import { Link, Redirect } from 'react-router-dom';

import Check from './fields/check';
import Text from './fields/text';
import TextNumber from './fields/textNumber';
import Select from './fields/select';
import Textarea from './fields/textarea';
import Isvg from 'react-inlinesvg';
import { connect } from 'react-redux';

import { Container, Row, Col } from 'reactstrap';

const required = (value) => (value ? undefined : 'Required');
const renderTextNumberField = ({
  input,
  label,
  placeholder,
  disabled,
  meta: { touched, error },
  type,
}) => (
  <TextNumber
    placeholder={placeholder}
    errorText={touched && error}
    label={label}
    disabled={disabled}
    error={touched && error}
    type={type}
    {...input}
  />
);

const renderCheckField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <Check
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    // multilang={multilang}
    // lang={lang}

    {...input}
  />
);

const renderSelectField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
  children,
  additionalAction,
  scope,
  disabled,
}) => (
  <Select
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    additionalAction={additionalAction}
    scope={scope}
    disabled={disabled}
    {...input}
    children={children}
  />
);

const renderTextField = ({
  input,
  label,
  placeholder,
  meta: { touched, error },
  type,
  disabled,
}) => (
  <Text
    placeholder={placeholder}
    errorText={touched && error}
    label={label}
    error={touched && error}
    type={type}
    disabled={disabled}
    {...input}
  />
);

const renderTextareaField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <Textarea
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    {...input}
  />
);

class CartAddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seePassword: false,
      disabledCountrySelect: false
    };
  }
  componentDidMount () {
    const priceListCountryMap = {
      'Price list AT': 'AT',
      'Price list DE': 'DE',
      'Price list DK': 'DK',
      'Price list NL': 'NL',
      'Price list BE': 'BE',
      'Price list FR': 'FR',
      'Price list LV': 'LV',
      'Price list SE': 'SE',
    }

    const {customerGroups = []} = this.props.initialValues;
    const priceListNames = Object.keys(priceListCountryMap);
    const targetPriceList =  customerGroups.find(group => priceListNames.includes(group.name));

    if (targetPriceList && targetPriceList.name) {
      const countryCode = priceListCountryMap[targetPriceList.name];

      if (countryCode) {
        this.props.changeCountry(countryCode);
        this.setState({
          disabledCountrySelect: true
        });
      }
    }
  }

  render() {
    const {handleSubmit, pristine, reset, submitting} = this.props;

    return (
        <form onSubmit={handleSubmit} className="login-form accoutn-info-form">
        {!this.props.sameAsBillingAddress ? (
          <Row>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="shippingData.company"
                  component={renderTextField}
                  label={'Företagsnamn eller Förnamn och Efternamn *'.translate(
                    this.props.lang,
                  )}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                    name="shippingData.country"
                    component={renderSelectField}
                    label={'Land/Region *'}
                    validate={[required]}
                    disabled={this.state.disabledCountrySelect}
                >
                  <option value="">{'Välj land / region...'.translate(this.props.lang)}</option>
                  <option value="AT">{'Österrike'.translate(this.props.lang)}</option>
                  <option value="BE">{'Belgien'.translate(this.props.lang)}</option>
                  <option value="BG">{'Bulgarien'.translate(this.props.lang)}</option>
                  <option value="CY">{'Cypern'.translate(this.props.lang)}</option>
                  <option value="CZ">{'Tjeckien'.translate(this.props.lang)}</option>
                  <option value="DE">{'Tyskland'.translate(this.props.lang)}</option>
                  <option value="DK">{'Danmark'.translate(this.props.lang)}</option>
                  <option value="EE">{'Estland'.translate(this.props.lang)}</option>
                  <option value="ES">{'Spanien'.translate(this.props.lang)}</option>
                  <option value="FI">{'Finland'.translate(this.props.lang)}</option>
                  <option value="FR">{'Frankrike'.translate(this.props.lang)}</option>
                  <option value="GR">{'Grekland'.translate(this.props.lang)}</option>
                  <option value="HR">{'Kroatien'.translate(this.props.lang)}</option>
                  <option value="HU">{'Ungern'.translate(this.props.lang)}</option>
                  <option value="IE">{'Irland'.translate(this.props.lang)}</option>
                  <option value="IT">{'Italien'.translate(this.props.lang)}</option>
                  <option value="LT">{'Litauen'.translate(this.props.lang)}</option>
                  <option value="LU">{'Luxemburg'.translate(this.props.lang)}</option>
                  <option value="LV">{'Lettland'.translate(this.props.lang)}</option>
                  <option value="MT">{'Malta'.translate(this.props.lang)}</option>
                  <option value="NL">{'Nederländerna'.translate(this.props.lang)}</option>
                  <option value="PL">{'Polen'.translate(this.props.lang)}</option>
                  <option value="PT">{'Portugal'.translate(this.props.lang)}</option>
                  <option value="RO">{'Rumänien'.translate(this.props.lang)}</option>
                  <option value="SE" selected="selected">{'Sverige'.translate(this.props.lang)}</option>
                  <option value="SI">{'Slovenien'.translate(this.props.lang)}</option>
                  <option value="SK">{'Slovakien'.translate(this.props.lang)}</option>

                </Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                    name="shippingData.address1"
                    component={renderTextField}
                    label={'Gatuadress *'.translate(this.props.lang)}
                    validate={[required]}
                ></Field>
              </div>
            </Col>
            {/* <Col lg="6" style={{ marginTop: 27 }}>
                                <div className="field-wrap">
                                    <Field
                                        name="shippingData.address2"
                                        component={renderTextField}
                                    ></Field>
                                </div>
                            </Col> */}
            <Col lg="12">
              <div className="field-wrap">
                <Field
                    name="shippingData.postalCode"
                    component={renderTextNumberField}
                    label={'Postnummer *'.translate(this.props.lang)}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="shippingData.city"
                  component={renderTextField}
                  label={'Ort *'.translate(this.props.lang)}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="shippingData.phone"
                  component={renderTextField}
                  label={'Telefon *'.translate(this.props.lang)}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                { this.props.deliveryTo === 'custom' ?
                    <Field
                        name='deliveryNotificationEmail'
                        component={renderTextField}
                        label={'E-postadress *'.translate(this.props.lang)}
                        validate={[required]}
                    ></Field>
                    :
                    <Field
                        name='shippingData.email'
                        component={renderTextField}
                        label={'E-postadress *'.translate(this.props.lang)}
                        validate={[required]}
                    ></Field>
                }
              </div>
            </Col>
          </Row>
        ) : null}
      </form>
    );
  }
}

CartAddressForm = reduxForm({
  form: 'cartAddressForm', // a unique identifier for this form
})(CartAddressForm);

// Decorate with connect to read form values
const selector = formValueSelector('cartAddressForm'); // <-- same as form name
CartAddressForm = connect((state) => {
      const deliverToDifferentAddress = selector(
          state,
          'deliverToDifferentAddress',
      );
      return {
        deliverToDifferentAddress: deliverToDifferentAddress,
      }
    },
    {
      changeCountry: (value) => change('cartAddressForm', 'shippingData.country', value)
    }
)(CartAddressForm);

export default CartAddressForm;
