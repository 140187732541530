import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';

import News from '../components/news';
import homeBackgroundImage from '../assets/images/home.jpg';
import stedger from '../assets/images/stedger.png';
import f2 from '../assets/images/f2.jpg';
import f3 from '../assets/images/f3.jpg';
import f4 from '../assets/images/f4-wide.jpg';
import n1 from '../assets/images/n1.jpg';
import s1 from '../assets/images/s1.jpg';
import s2 from '../assets/images/s2.jpg';
import s3 from '../assets/images/s3.jpg';
import s4 from '../assets/images/s4.jpg';
import s5 from '../assets/images/s5.jpg';
import bannerTest from '../assets/images/banner-example.jpg';
import instagram from '../assets/svg/instagram.svg';
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper';
import Article from '../components/article';
import { API_ENDPOINT } from '../constants';

var striptags = require('striptags');

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      ...props.initialData,
      products: [],
      fscProducts: [],
      sections: [],
      brand: [],
      categories: [],
      activeIndex: 0,
      slides: [],
      instagramPhotos: [],
    };
  }

  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex ===
      this.state.slides
        .filter(
          (item) =>
            !item.onlyLoggedUsers || (item.onlyLoggedUsers && this.props.uData),
        )
        .filter(
          (item) =>
            !item.hiddenForB2C ||
            (item.hiddenForB2C &&
              this.props.uData &&
              !this.props.uData.creditCardPayment),
        ).filter((item) => !item.visibleForCampaignUsers || (item.visibleForCampaignUsers && this.props.uData && this.props.uData.customerGroups && this.props.uData.customerGroups?.find(item => ['Price list 1 SEK', 'Price list 1 EUR', 'Price list 1 DKK', 'Price list 2 SEK', 'Price list 2 EUR', 'Price list 2 DKK', 'Price list 3 SEK'].indexOf(item.name) !== -1))).length -
        1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.slides
            .filter(
              (item) =>
                !item.onlyLoggedUsers ||
                (item.onlyLoggedUsers && this.props.uData),
            )
            .filter(
              (item) =>
                !item.hiddenForB2C ||
                (item.hiddenForB2C &&
                  this.props.uData &&
                  !this.props.uData.creditCardPayment),
            ).filter((item) => !item.visibleForCampaignUsers || (item.visibleForCampaignUsers && this.props.uData && this.props.uData.customerGroups && this.props.uData.customerGroups?.find(item => ['Price list 1 SEK', 'Price list 1 EUR', 'Price list 1 DKK', 'Price list 2 SEK', 'Price list 2 EUR', 'Price list 2 DKK', 'Price list 3 SEK'].indexOf(item.name) !== -1))).length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    let params = getSearchParams(this.props[0].location.search, {});

    if (this.props.loadDataWithQuery) {
      for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
        this.props.loadDataWithQuery[i](
          window.fetch,
          this.props.lang,
          this.props[0].match,
          params,
          this.props[0].location.pathname,
        ).then((data) => {
          this.setState(
            {
              ...data,
              loading: null,
            },
            () => {
              this.props.updateMeta(
                this.props.generateSeoTags(this.state, this.props.lang),
              );
            },
          );
        });
      }
    }

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang),
    );
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    this.get();
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.pathname != this.props[0].location.pathname) {
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.searchResultsWrap &&
      this.searchResultsWrap.current &&
      !this.searchResultsWrap.current.contains(event.target)
    ) {
      this.setState({
        showSuggestions: false,
      });
    }
  };

  render() {
    let imageSize =
      typeof window != 'undefined' && window.innerWidth < 768 ? 'xs' : 'lg';

    return (
      <div>
        <section className="home-slider">
          <Carousel
            activeIndex={this.state.activeIndex}
            next={this.next}
            previous={this.previous}
            interval={5000}
            ride={'carousel'}
          >
            {this.state.slides
              .filter(
                (item) =>
                  !item.onlyLoggedUsers ||
                  (item.onlyLoggedUsers && this.props.uData),
              )
              .filter(
                (item) =>
                  !item.hiddenForB2C ||
                  (item.hiddenForB2C &&
                    this.props.uData &&
                    !this.props.uData.creditCardPayment),
              ).filter((item) => !item.visibleForCampaignUsers || (item.visibleForCampaignUsers && this.props.uData && this.props.uData.customerGroups && this.props.uData.customerGroups?.find(item => ['Price list 1 SEK', 'Price list 1 EUR', 'Price list 1 DKK', 'Price list 2 SEK', 'Price list 2 EUR', 'Price list 2 DKK'].indexOf(item.name) !== -1)))
              .map((item, idx) => {
                return (
                  <CarouselItem
                    key={item._id}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                  >
                    <div className="slide">
                      <div className="layer-15"></div>
                      {/* <img src={API_ENDPOINT + (item.mobileImage && imageSize == 'xs' ? item.mobileImage : item.image)} /> */}
                      {imageSize == 'xs' &&
                      item.mobileImage &&
                      item.mobileImage.indexOf('.mp4') !== -1 ? (
                        <video loop muted autoPlay playsInline>
                          <source
                            src={
                              API_ENDPOINT.replace('testapi', 'api') +
                              item.mobileImage
                            }
                          ></source>
                        </video>
                      ) : item.mobileImage && imageSize == 'xs' ? (
                        <img
                          src={
                            API_ENDPOINT.replace('testapi', 'api') +
                            item.mobileImage
                          }
                        />
                      ) : item.image &&
                        imageSize != 'xs' &&
                        item.image.indexOf('.mp4') !== -1 ? (
                        <video loop muted autoPlay playsInline>
                          <source src={API_ENDPOINT + item.image}></source>
                        </video>
                      ) : (
                        <img
                          src={
                            API_ENDPOINT.replace('testapi', 'api') + item.image
                          }
                        />
                      )}
                      <div className="slide-content">
                        <h6>
                          {Object.translate(item, 'subtitle', this.props.lang)}
                        </h6>
                        <h1
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              item,
                              'title',
                              this.props.lang,
                            ),
                          }}
                        ></h1>
                        {Object.translate(
                          item,
                          'button.text',
                          this.props.lang,
                        ) ? (
                          <a
                            target={'_blank'}
                            href={
                              Object.translate(
                                item,
                                'button.link',
                                this.props.lang,
                              ) + (this.props.lang == 'en' ? '?lang=en' : '')
                            }
                            className="button-clasic"
                          >
                            <div className="button-inner">
                              {Object.translate(
                                item,
                                'button.text',
                                this.props.lang,
                              )}
                            </div>
                            <div className="button-outer">
                              {Object.translate(
                                item,
                                'button.text',
                                this.props.lang,
                              )}
                            </div>
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </CarouselItem>
                );
              })}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={this.previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={this.next}
            />
          </Carousel>
        </section>
        <div className="home-nav-list">
          <div className={this.state.subCat ? 'nav-list' : 'nav-list'}>
            <ul
              className="menu"
              ref={(node) => {
                this.wrapperRef4 = node;
              }}
            >
              {this.props.rootCategories
                .filter((item) => !item.brandCategory)
                .map((item, idx) => {
                  if (idx < 2) {
                    return (
                      <li
                        key={item._id}
                        onClick={() => {
                          this.props.handleMenuCategory(item);
                        }}
                      >
                        <Link>
                          {Object.translate(item, 'name', this.props.lang)}
                        </Link>
                      </li>
                    );
                  } else {
                    return (
                      <li key={item._id}>
                        <Link
                          to={Object.translate(
                            item,
                            'breadcrumb',
                            this.props.lang,
                          )}
                        >
                          {Object.translate(item, 'name', this.props.lang)}
                        </Link>
                      </li>
                    );
                  }
                })}
              <li
                onClick={() => {
                  this.props.handleMenuCategory(true);
                }}
              >
                <Link>{'VARUMÄRKEN'.translate(this.props.lang)}</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="featured-box-3">
          <Container fluid>
            <Row>
              <Col lg={{ size: 12 }}>
                <Row>
                  {this.state.categories.map((item, idx) => {
                    return (
                      <Col lg="6" key={item._id}>
                        <Link
                          to={Object.translate(
                            item,
                            'breadcrumb',
                            this.props.lang,
                          )}
                        >
                          <div className="box">
                            <img
                              src={
                                API_ENDPOINT.replace('testapi', 'api') +
                                Object.get(item, `homeImage.${imageSize}`)
                              }
                            />
                            <div>
                              <h3>
                                {Object.translate(
                                  item,
                                  'name',
                                  this.props.lang,
                                )}
                              </h3>
                              <Link
                                to={Object.translate(
                                  item,
                                  'breadcrumb',
                                  this.props.lang,
                                )}
                                className="button-clasic"
                              >
                                <div className="button-inner">
                                  {'SE HELA SORTIMENTET'.translate(
                                    this.props.lang,
                                  )}
                                </div>
                                <div className="button-outer">
                                  {'SE HELA SORTIMENTET'.translate(
                                    this.props.lang,
                                  )}
                                </div>
                              </Link>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="news">
          <Container fluid>
            <Row>
              <Col lg="12">
                <div className="news-content">
                  <h2>{'Våra VARUMÄRKEN'.translate(this.props.lang)}</h2>
                  <h6></h6>
                </div>
              </Col>
            </Row>

            <Row className="justify-center home-brands">
              <Col lg="12">
                <Row style={{justifyContent: 'center'}}>
                  {this.state.brand.map((item, idx) => {
                    if (Object.translate(item, 'link', this.props.lang))
                    return (
                      <Col lg="2" xs="6" key={idx}>
                        {Object.translate(item, 'link', this.props.lang) && Object.translate(item, 'link', this.props.lang).indexOf('https://') !== -1 ?
                           <a
                           href={Object.translate(item, 'link', this.props.lang)}
                         >
                           <img src={API_ENDPOINT.replace('testapi', 'api') + item.image} />
                         </a>
                        :

                        <Link
                          to={Object.translate(item, 'link', this.props.lang)}
                        >
                          <img src={API_ENDPOINT.replace('testapi', 'api') + item.image} />
                        </Link>
                  }
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="news">
          <Container fluid>
            <Row>
              <Col lg="12">
                <div className="news-content">
                  <h2>{'Våra Nyheter'.translate(this.props.lang)}</h2>
                  <h6>
                    {'Missa inte nya produkter i vårt sortiment'.translate(
                      this.props.lang,
                    )}
                  </h6>
                </div>
              </Col>
            </Row>

            <Row className="justify-center">
              <Col lg="8">
                <Row>
                  {this.state.products.map((item, idx) => {
                    if (
                      typeof window !== 'undefined' &&
                      String(localStorage.getItem('guestToken')).indexOf(
                        'retrack-',
                      ) === -1
                    ) {
                      if (
                        !this.props.uData &&
                        item.price &&
                        this.props[0].location.pathname.indexOf('/sale') == -1
                      ) {
                        item.price = null;
                      }
                    }

                    return (
                      <Col lg="4" key={item._id}>
                        <Article
                          wishlistDict={this.props.wishlistDict}
                          addToWishlist={this.props.addToWishlist}
                          pathname={this.props[0].location.pathname}
                          uData={this.props.uData}
                          lang={this.props.lang}
                          {...item}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>

            <div className="news-button">
              <Link to="/nyheter" className="button-transparent">
                <div className="button-inner">
                  {'VISA ALLA'.translate(this.props.lang)}
                </div>
                <div className="button-outer">
                  {'VISA ALLA'.translate(this.props.lang)}
                </div>
              </Link>
            </div>
          </Container>
        </div>

        <div className="banner">
          <Container fluid>
            <Row>
              <Col lg="12">
                <div className="banner-content text-left">
                  <img
                    src={
                      API_ENDPOINT.replace('testapi', 'api') +
                      Object.get(this.state, 'section[1].image')
                    }
                    className="bg-image"
                  />
                  <div className="text-left-content">
                    <h5>
                      {Object.translate(
                        this.state,
                        'section[1].subtitle',
                        this.props.lang,
                      )}
                    </h5>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: Object.translate(
                          this.state,
                          'section[1].title',
                          this.props.lang,
                        ),
                      }}
                    ></h3>
                    <a
                      target="_blank"
                      href={Object.translate(
                        this.state,
                        'section[1].button.link',
                        this.props.lang,
                      )}
                      className="button-clasic"
                    >
                      <div className="button-inner">
                        {Object.translate(
                          this.state,
                          'section[1].button.text',
                          this.props.lang,
                        )}
                      </div>
                      <div className="button-outer">
                        {Object.translate(
                          this.state,
                          'section[1].button.text',
                          this.props.lang,
                        )}
                      </div>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="banner-no-text">
          <Container fluid>
            <Row>
              <Col lg="12">
                  <img
                    src={
                      API_ENDPOINT.replace('testapi', 'api') +
                      Object.get(this.state, 'section[2].image')
                    }
                  />
                  <div className="banner-no-text-content">
                    <h6>
                      {Object.translate(
                        this.state,
                        'section[2].subtitle',
                        this.props.lang,
                      )}
                    </h6>
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: Object.translate(
                          this.state,
                          'section[2].title',
                          this.props.lang,
                        ),
                      }}
                    ></h2>
                    <a
                      href={Object.translate(
                        this.state,
                        'section[2].button.link',
                        this.props.lang,
                      )}
                      className="button-clasic"
                    >
                      <div className="button-inner">
                        {Object.translate(
                          this.state,
                          'section[2].button.text',
                          this.props.lang,
                        )}
                      </div>
                      <div className="button-outer">
                        {Object.translate(
                          this.state,
                          'section[2].button.text',
                          this.props.lang,
                        )}
                      </div>
                    </a>
                  </div>
              </Col>
            </Row>
          </Container>
        </div>

        <h2 className="section-3-title">
          {Object.translate(this.state, 'section[3].title', this.props.lang)}
        </h2>
        <div className="cta">
          {typeof window !== 'undefined' && window.innerWidth < 768 ? (
            <img
              src={
                API_ENDPOINT + Object.get(this.state, 'section[3].mobileImage')
              }
            />
          ) : (
            <img
              src={API_ENDPOINT + Object.get(this.state, 'section[3].image')}
            />
          )}
          <div className="cta-content">
            <h3>
              <span>
                {Object.translate(
                  this.state,
                  'section[3].text',
                  this.props.lang,
                )}
              </span>
            </h3>
            <Link
              to={Object.translate(
                this.state,
                'section[3].button.link',
                this.props.lang,
              )}
              className="button-clasic"
            >
              <div className="button-inner">
                {Object.translate(
                  this.state,
                  'section[3].button.text',
                  this.props.lang,
                )}
              </div>
              <div className="button-outer">
                {Object.translate(
                  this.state,
                  'section[3].button.text',
                  this.props.lang,
                )}
              </div>
            </Link>
          </div>
        </div>

        <div className="banner-no-text sterger-banner">
          <Container fluid>
            <Row>
            <Col lg="12">
                <div className="news-content">
                  <h2>{'INTEGRATE EASILY'.translate(this.props.lang)}</h2>
                  <h6></h6>
                </div>
              </Col>

              <Col lg="12" className="content">
                 
                    <img
                      src={
                        stedger
                      }
                    />
                    <p>{'Venture Design has partnered up with Stedger, one of Europes biggest dropshipping partners.'.translate(this.props.lang)}</p>
                    <Link
              to={'/stedger'}
              className="button-clasic"
            >
              <div className="button-inner">
              {'FIND OUT MORE'.translate(this.props.lang)}
              </div>
              <div className="button-outer">
              {'FIND OUT MORE'.translate(this.props.lang)}
              </div>
            </Link>

              </Col>
            </Row>
           
          </Container>
        </div>

        <div className="banner-no-text contain">
          <Container fluid>
            <Row>
            <Col lg="12">
                <div className="news-content">
                  <h2>{'VI VÄRNAR OM VÅRA SKOGAR'.translate(this.props.lang)}</h2>
                  <h6></h6>
                </div>
              </Col>
              <Col lg="12">
                <Link
                  to={Object.translate(
                    this.state,
                    'section[4].link',
                    this.props.lang,
                  )}
                >
                  {typeof window !== 'undefined' && window.innerWidth < 768 ? (
                    <img
                      src={
                        API_ENDPOINT.replace('testapi', 'api') +
                        Object.get(this.state, 'section[4].mobileImage')
                      }
                    />
                  ) : (
                    <img
                      src={
                        API_ENDPOINT.replace('testapi', 'api') +
                        Object.get(this.state, 'section[4].image')
                      }
                    />
                  )}
                </Link>
              </Col>
              <Col lg="12">
                <div className="news-content" style={{paddingBottom: 20, paddingTop: 40}}>
                  <h2>{'VÅRA FSC PRODUKTER'.translate(this.props.lang)}</h2>
                </div>
              </Col>

            </Row>
           
          </Container>
        </div>
        <Container>
        <Row className="justify-center">
              <Col lg="12" style={{paddingBottom: 40}}>
                <Row>
                  {this.state.fscProducts.map((item, idx) => {
                    if (
                      typeof window !== 'undefined' &&
                      String(localStorage.getItem('guestToken')).indexOf(
                        'retrack-',
                      ) === -1
                    ) {
                      if (
                        !this.props.uData &&
                        item.price &&
                        this.props[0].location.pathname.indexOf('/sale') == -1
                      ) {
                        item.price = null;
                      }
                    }

                    return (
                      <Col lg="4" key={item._id}>
                        <Article
                          wishlistDict={this.props.wishlistDict}
                          addToWishlist={this.props.addToWishlist}
                          pathname={this.props[0].location.pathname}
                          uData={this.props.uData}
                          lang={this.props.lang}
                          {...item}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
        </Container>

        <div className="social-media">
          <Container fluid>
            <Row>
              <Col lg="12">
              <div className="news-content" style={{paddingBottom: 0}}>
                  <h2>{'Följ oss på instagram'.translate(this.props.lang)}</h2>
                </div>

                {/* <div className="flex">
                  <h5>{'Följ oss på instagram'.translate(this.props.lang)}</h5>
                  <span>
                    <Isvg src={instagram} className="social-icon" />
                  </span>
                </div> */}
                <a
                  href="https://www.instagram.com/venture.design/"
                  target="_blank"
                >
                  @venture.design
                </a>
              </Col>
            </Row>

            <Row className="social-media-row" style={{ paddingTop: 50 }}>
              {this.state.instagramPhotos.map((item, idx) => {
                return (
                  <Col lg="2" xs="12" key={idx}>
                    <a href={item.permalink} target="_blank">
                      {item.media_url &&
                      item.media_url.indexOf('.mp4') !== -1 ? (
                        <video autoPlay loop muted playsInline>
                          <source src={item.media_url} />
                        </video>
                      ) : (
                        <img src={item.media_url} />
                      )}
                    </a>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Page(HomePage);
